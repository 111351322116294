import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className={"title"}>TV-1</div>
      <div className={"subtitle"}>https://vigilant-colden-6d471c.netlify.com.</div>
      <div id={"crackHider"}></div>
    </div>
  );
}

export default App;
